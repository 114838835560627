import React from "react";
import { ExperimentProvider, IExperiment } from "@btransport/react-experimentation";
import { FeatureFlags } from "@btu-tools/mfe-feature-toggles/dist/types";
import { BUIProvider } from "@bookingcom/bui-react";
import { DictionaryObject } from "@bookingcom/lingojs-parser";
import { getI18NStore } from "@btransport/lingo-client-utils";
import { I18nProvider } from "@bookingcom/lingojs-react";

interface HeaderProviderProps {
	pageName: string;
	abuLocale: string;
	lingoTranslations: DictionaryObject;
	selectedCurrency: string;
	paymentCurrency: string;
	selectedLanguage: string;
	updateUrlOnLanguageChange: boolean;
	experiments?: Record<string, IExperiment>;
	featureFlags?: FeatureFlags;
	children?: React.ReactNode;
	defaultViewportSize: "small" | "large";
	languagesToHide: string[];
	currenciesToHide: string[];
}

interface HeaderContextProps {
	pageName: string;
	selectedCurrency: string;
	paymentCurrency: string;
	selectedLanguage: string;
	updateUrlOnLanguageChange: boolean;
	featureFlags?: FeatureFlags;
	languagesToHide: string[];
	currenciesToHide: string[];
}

const HeaderContext = React.createContext<HeaderContextProps>(null);

export const HeaderProvider: React.FC<Partial<HeaderProviderProps>> = ({
	pageName,
	abuLocale,
	lingoTranslations,
	selectedCurrency,
	paymentCurrency,
	selectedLanguage = "",
	updateUrlOnLanguageChange,
	experiments = {},
	children,
	defaultViewportSize,
	languagesToHide = [],
	currenciesToHide = [],
}) => {
	const i18n = getI18NStore(abuLocale, lingoTranslations);
	return (
		<HeaderContext.Provider
			value={{
				pageName,
				selectedCurrency,
				paymentCurrency,
				selectedLanguage,
				updateUrlOnLanguageChange,
				languagesToHide: languagesToHide.map((languageToHide) => languageToHide.toLowerCase()),
				currenciesToHide: currenciesToHide.map((currencyToHide) => currencyToHide.toLowerCase()),
			}}
		>
			<ExperimentProvider experiments={experiments}>
				<I18nProvider store={i18n}>
					<BUIProvider defaultViewportSize={defaultViewportSize}>{children}</BUIProvider>
				</I18nProvider>
			</ExperimentProvider>
		</HeaderContext.Provider>
	);
};

export const useHeaderContext = (): HeaderContextProps => {
	return React.useContext<HeaderContextProps>(HeaderContext);
};
